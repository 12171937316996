import CarouselOffer from "./carousel-offer"

export default function HomeOffer({populars, bestsales}: any) {
  // console.log(populars, bestsales)

  return (
    <section className="px-2 md:px-0 mb-24">
      <div className="container mx-auto flex flex-col md:flex-row gap-8">
        <div className="lg:col-span-2 w-full md:w-7/12">
          <h2 className="text-center md:text-left font-display text-2xl text-gray-900 font-bold mb-6">Buku Terpopuler</h2>
          <CarouselOffer dataCarousel={populars} type="popular" setTimer={2000} />

        </div>
        <div className="w-full md:w-5/12">
          <h2 className="text-center md:text-left font-display text-2xl text-gray-900 font-bold mb-6">Buku Terlaris</h2>
          <CarouselOffer dataCarousel={bestsales} type="bestsales" setTimer={2500} />

        </div>
      </div>
    </section>
  )
}