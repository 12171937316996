import { useEffect } from 'react'
import { useSpringCarousel } from 'react-spring-carousel'
import Image from "remix-image"

export default function CarouselOffer({dataCarousel, type, setTimer = 2000}: any) {
  // console.log(populars, bestsales)

  const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
    initialActiveItem: 0,
    withLoop: true,
    items: dataCarousel.map((carousel: any) => ({
        id: carousel.id,
        renderItem: (
          <div key={carousel.title} 
            className={`${type == "popular" ? "from-pink-400 to-pink-300 " : "from-blue-800 to-blue-500 " }relative w-full bg-gradient-to-t rounded-xl h-64 p-4 flex flex-col md:flex-row justify-around items-center bg-cover shadow-md`}>
            <Image
              className="block h-24 w-auto md:h-48 drop-shadow-lg pointer-events-none" alt={carousel.title}
              src={ENV.BACKEND_URL + carousel.image.data.attributes.url}
              loaderUrl="/api/image"
              dprVariants={[1, 3]}
            />
            <div className="w-full md:w-1/3 ml-4 text-center">
              <h3 className="text-xl md:text-2xl font-bold text-white drop-shadow-sm mb-2">{carousel.title}</h3>
              <h4 className="text-sm text-gray-100">{carousel.subtitle}</h4>
              { carousel.button_name !== null
                  ? <a href={carousel.link} 
                      className={`${type == "popular" ? "bg-pink-600 focus:ring-red-300 " : "bg-blue-400 focus:ring-blue-200 "}mt-4 inline-block text-white hover:opacity-90 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0`}>
                        {carousel.button_name}
                    </a>
                  : null
              }
            </div>
          </div>
        )
      }))
  })

  // slideshow
  useEffect(() => {
    // if (dataCarousel.length === null || dataCarousel.length === 1) setTimer = 0

    const timer = setInterval(() => {
      slideToNextItem()
    }, setTimer)
    return () => {
      window.clearInterval(timer)
    }
  }, [slideToNextItem])

  return (
    <div className="relative overflow-hidden">

      {carouselFragment}

      <button onClick={slideToPrevItem} type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full p-1 md:px-2 cursor-pointer group focus:outline-none" data-carousel-prev="">
        <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-2 group-focus:ring-white group-focus:outline-none">
          <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button onClick={slideToNextItem} type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full p-1 md:px-2 cursor-pointer group focus:outline-none" data-carousel-next="">
        <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-2 group-focus:ring-white group-focus:outline-none">
          <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  )
}