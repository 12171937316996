export default function HomeAbout({about}: any) {
  return (
    <section className="px-2 md:px-0 mb-24">
      <div className="container relative overflow-hidden mx-auto text-center py-12 px-2 bg-white rounded-xl shadow-md">
        <h1 dangerouslySetInnerHTML={{ __html: about.title }} className="mb-4 font-display text-4xl md:text-5xl font-extrabold tracking-tight leading-none text-gray-800 z-10" />
        <p className="mb-6 text-base md:text-lg font-normal text-gray-700 lg:text-xl sm:px-16 xl:px-48 z-10">
          {about.content}
        </p>

        <div className="absolute bg-primary opacity-10 w-96 h-96 -top-12 -right-16 rounded-full shadow-2xl z-0"></div>
        <div className="absolute bg-secondary opacity-10 w-96 h-96 -bottom-32 -left-16 rounded-full shadow-2xl z-0"></div>
      </div>
    </section>
  )
}