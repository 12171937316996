import { Link } from "@remix-run/react"
import BookImage from "~/shared/components/Book/image"

export default function HomeProduct({products}: any) {
  // console.log(products)

  return (
    <section className="px-4 md:px-0 mb-12 md:mb-16 bg-transparent">
      <div className="container mx-auto relative">
        <h2 className="text-center font-display text-3xl md:text-4xl text-gray-900 font-bold mb-12">Produk <span className="text-primary">Kami</span></h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-8 mb-24">
          <div className="absolute -bottom-12 h-5/6 md:h-1/2 w-full bg-white rounded-xl">
            <div className="absolute -top-12 md:-top-1/2 h-full w-full bg-white rounded-xl -skew-y-6"></div>
          </div>
          {products?.map((product: any) =>
            // <Link to={'/katalog/' + product.attributes.slug} className="h-48 p-2 bg-sky-200 rounded-xl flex flex-col justify-center items-center z-10 hover:drop-shadow-md hover:scale-105 transition" key={product.attributes.title}>
            //   <img className="mb-4" src={ENV.BACKEND_URL + product.attributes.image.data.attributes.url} width="200" alt={product.attributes.title} />
            //   <h3 className="text-base text-tertiary font-semibold text-center">{product.attributes.title}</h3>
            // </Link>
            <Link to={'/katalog/' + product.attributes.slug} key={product.attributes.title} className="flex justify-center mb-8">
              <BookImage logo={ENV.BACKEND_URL + product.attributes.image.data.attributes.url} title={product.attributes.title} />
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}