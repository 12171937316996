import {
  useTransition,
  useSpring,
  config,
  animated
} from '@react-spring/web'

export default function HomeProduct({features}: any) {

  const titleSpring = useSpring({
    delay: 750,
    to: { opacity: 1, transform: 'translate(0, 0)'},
    from: { opacity: 0, transform: 'translate(0, -2rem)'}
  })
  // motion feature items
  const itemTransitions = useTransition(features ? features : [], {
    delay: 1000,
    trail: 1000 / features.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    config: config.gentle
  })

  return (
    <section className="px-4 md:px-0 mb-12 md:mb-16 bg-transparent">
      <div className="container mx-auto">
        <animated.h2 style={titleSpring} className="font-display text-3xl md:text-4xl text-center text-gray-900 font-bold mb-12">
          Kenapa harus pilih 
          <span className="text-secondary"> Gema</span>
          <span className="text-primary"> Nusa</span> ?</animated.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {itemTransitions((style, feature) => (
            <animated.div style={{...style}} className="group h-auto p-8 bg-white rounded-xl flex flex-col justify-start items-center hover:drop-shadow-md" key={feature.title}>
              <div className="bg-secondary text-white rounded-full h-20 w-20 flex justify-center items-center mb-4">
                <i className={`${feature.icon} text-4xl transition group-hover:rotate-12 group-hover:scale-125`}></i>
              </div>
              <h3 className="text-2xl font-bold text-secondary text-center mb-4">{feature.title}</h3>
              <p className="text-gray-500 text-center">{feature.subtitle}</p>
            </animated.div>
          ))}
        </div>
      </div>
    </section>
  )
}