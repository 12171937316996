import { json } from "@remix-run/node";
import { useCatch, useLoaderData } from "@remix-run/react";
import type { LoaderFunction } from "@remix-run/node";

import { getHomeDatas } from "~/models/home.server";

import Carousel from "~/shared/components/Carousel";
import Features from "~/shared/components/Features"
import HomeProduct from "~/shared/components/HomeProduct";
import HomeOffer from "~/shared/components/HomeOffer";
import HomeAbout from "~/shared/components/HomeAbout";

type LoaderData = {
  homeDatas: Awaited<ReturnType<typeof getHomeDatas>>
}

export const loader: LoaderFunction = async () => {
  let homeDatas = await getHomeDatas()
  return json<LoaderData>({ homeDatas })
}

export default function Index() {
  let { homeDatas } = useLoaderData() as LoaderData
  let homepage = homeDatas.homepage?.data.attributes
  let products = homeDatas.products?.data
  // console.log(homepage)
  
  return (
    <main>
      <Carousel carousels={homepage.Carousel} />
      <Features features={homepage.Feature} />
      <HomeProduct products={products} />
      <HomeOffer populars={homepage.populars} bestsales={homepage.bestsales} />
      <HomeAbout about={homepage.about} />
    </main>
  );
}

export function CatchBoundary() {
  const caught = useCatch();

  return (
    <div className="h-screen flex flex-col justify-center flex-1 text-red-500 boxPattern">
      <div className="leading-none text-center">
        <h1 className="font-mono text-[25vw]">{caught.status}</h1>
        <pre>
          <code>{JSON.stringify(caught.data)}</code>
        </pre>

      </div>
    </div>
  );
}