import { useEffect } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useSpringCarousel } from 'react-spring-carousel'
import Image from "remix-image"
import Button from '~/shared/components/Button'

export default function Carousel({carousels}: any) {
  // console.log("carousels", carousels)

  const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
    initialActiveItem: 0,
    withLoop: true,
    items: carousels.map((carousel: any) => ({
        id: carousel.id,
        renderItem: (
          <div key={carousel.title} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20 bg-gray-200" data-carousel-item="">
            {!carousel.image_only &&
              <div className="w-full md:w-3/4 h-full flex flex-col items-center md:items-start justify-end md:justify-center bg-gradient-to-t md:bg-gradient-to-r from-secondary absolute -translate-y-1/2 top-1/2 left-0 z-10 px-10 md:px-24 pb-6 md:pb-12">
                <h1 className="text-lg md:text-3xl text-center md:text-left font-semibold text-white sm:text-3xl">{carousel.title}</h1>
                <p className="hidden md:block text-sm md:text-base text-blue-100 mt-2">{carousel.subtitle}</p>
                {carousel.button_name !== null && <Button title={carousel.button_name} link={carousel.link} type="ghost_button" class_name='mt-6' is_fit icon={''} new_tab={false} is_icon={false} />}
              </div>
            }
            <Image
              className="absolute object-cover object-center block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 pointer-events-none" alt={carousel.title}
              src={ENV.BACKEND_URL + carousel.image.data.attributes.formats.medium.url}
              loaderUrl="/api/image"
              dprVariants={[1, 3]}
            />
          </div>
        )
      }))
  })

  // slideshow
  useEffect(() => {
    let countTimer = 2000
    if (carousels.length === null || carousels.length === 1) countTimer = 0
    const timer = setInterval(() => {
      slideToNextItem()
    }, 2000)
    return () => {
      window.clearInterval(timer)
    }
  }, [slideToNextItem])

  const showMotion = useSpring({ delay: 500, to: { opacity: 1, transform: 'translate(0, 0)' }, from: { opacity: 0, transform: 'translate(0, -2rem)' } })

  return (
    <section className="px-2 md:px-0 mb-12 md:mb-16">
      <animated.div style={showMotion} id="default-carousel" className="container mx-auto relative" data-carousel="slide">
          {/* <!-- Carousel wrapper --> */}
          <div className="relative h-40 md:h-96 overflow-hidden rounded-lg shadow-md">
              {carouselFragment}
          </div>
          {/* <!-- Slider indicators --> */}
          {/* <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
              <button type="button" className="w-3 h-3 rounded-full bg-white " aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
              <button type="button" className="w-3 h-3 rounded-full bg-white/50 hover:bg-white" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
              <button type="button" className="w-3 h-3 rounded-full bg-white/50 hover:bg-white" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
          </div> */}
          {/* <!-- Slider controls --> */}
          <button onClick={slideToPrevItem} type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full p-1 md:px-4 cursor-pointer group focus:outline-none" data-carousel-prev="">
              <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                  <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                  <span className="sr-only">Previous</span>
              </span>
          </button>
          <button onClick={slideToNextItem} type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full p-1 md:px-4 cursor-pointer group focus:outline-none" data-carousel-next="">
              <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                  <svg aria-hidden="true" className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  <span className="sr-only">Next</span>
              </span>
          </button>
      </animated.div>
    </section>
  )
}